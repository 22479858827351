import React from "react";
import { useState, useEffect } from "react";
import "./SubmitForm.css";
import axios from "axios";

/**
 * @author Rajesh
 * @description submit form with loanId, mobileNumber and UMRN (max amoung and dates if nach selection options is amend)
 * @param {Object} Object  { natchSelected, setOpen, setAlertType, setAlertMessage}
 * @returns submit form
 */
const SubmitForm = ({
  setOpen,
  setModel2,
  otpCount,
  setOtpCount,
  loanId,
  setLoanId,
  mobileNumber,
  setMobileNumber,
  pan,
  setPan,
  setRequestId,
  fullName,
  setFullName,
  setAlertType,
  setAlertMessage
}) => {


  useEffect(() => {
    setLoanId("");
    setMobileNumber("");
    setPan("");
  }, []);

  const checkPayload = (payload) => {
    //Check for missing Fields

    let payloadError = false;

    let { loan_id, mob_num, pan } =
      payload;

    loan_id = loan_id.trim();
    mob_num = mob_num.trim();
    pan = pan.trim();

    if (loan_id.length === 0 || /\s/.test(loan_id)) {
      payloadError = true;
    } else if (!/^[6-9][0-9]{9}$/.test(mob_num)) {
      payloadError = true;
    } else if (pan.length === 0 || !/^[A-Z]{3}[PCHABGJFLT]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/.test(pan)) {
      payloadError = true;
    }
    return payloadError;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      loan_id: loanId,
      mob_num: mobileNumber,
      pan: pan,
    };

    const payloadError = checkPayload(payload);
    if(!payload.loan_id || !payload.mob_num || !payload.pan){
      setOpen(true);
      setAlertType("failure")
      setAlertMessage("Kindly Provide all details");
      return;
    }
    if(payloadError){
      setOpen(true);
      setAlertType("failure")
      setAlertMessage("Incorrect details: please provide correct details");
      return;
    }
    if (!payloadError) {

      if (otpCount >= 3) {
        setOpen(true);
        setAlertType("failure");
        setAlertMessage("Please try again later");
        return;
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/send_otp`,
          payload,
          config

        );
        if (response?.data?.status === "success") {
          const capitalizeWords = (str) => {
            if (!str) return ''; 
            return str
              .trim() 
              .split(' ') 
              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
              .join(' '); 
          };
            const coappName = response.data?.data?.coappName;
            const formattedcoapName = capitalizeWords(coappName)
            const firstName = response?.data?.data?.first_name;
            const middleName = response?.data?.data?.middle_name;
            const lastName = response?.data?.data?.last_name;
            const formattedFirstName = capitalizeWords(firstName);
            const formattedMiddleName = capitalizeWords(middleName);
            const formattedLastName = capitalizeWords(lastName);
            const fullName = `${formattedcoapName ? `${formattedcoapName}` : ''}${formattedFirstName ? `${formattedFirstName}` : ''}${formattedMiddleName ? ` ${formattedMiddleName}` : ''}${formattedLastName ? ` ${formattedLastName}` : ''}`;
            setFullName(fullName);
            const newOtpCount = otpCount + 1;
            setOtpCount(newOtpCount);
            setRequestId(response?.data?.data?.otp_response?.requestId);
            setModel2(true);

        }
        if (response?.data?.status === "error") {
          setOpen(true);
          setAlertType("failure");
          setAlertMessage("Incorrect details: please provide correct details");

        }
      } catch (error) {
        setOpen(true);
        setAlertType("failure");
        setAlertMessage(error.response.data.message ?? "Incorrect details: please provide correct details");

      }
    } else {
      setOpen(true);
      setAlertType("failure");
      setAlertMessage("Kindly Provide all details");

    }
  };

  return (
    <div className="form-container">
      <form action="" className="form">
        <div className="form-upper">
          <div className="form-input">
            {loanId && <label for="loanId" >Loan ID</label>}
            <input
              id="loanId"
              type="text"
              placeholder="Loan ID"
              value={loanId}
              onChange={(e) => {
                setLoanId(e.target.value);
              }}
              style={{
                width: "100%",
              }}
              required
              autoComplete="off"
            />
          </div>

          <div className="form-input">
            {mobileNumber && <label for="mobileNumber" >Mobile Number</label>}
            <input
              id="mobileNumber"
              type="tel"
              placeholder="Mobile Number"
              maxLength="10"
              pattern="[1-9][0-9]{9}"
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(e.target.value);
              }}
              required
              autoComplete="off"
            />
          </div>

          <div className="form-input">
            {pan && <label for="pan" >PAN</label>}
            <input
              id="pan"
              type="text"
              placeholder="PAN"
              value={pan}
              onChange={(e) => {
                setPan(e.target.value);
              }}
              required
              autoComplete="off"
            />
          </div>
        </div>
        <button
          type="submit"
          className="submit-button"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SubmitForm;
