import React from "react";
import "./Sidepopup.css";

const Sidepopup = ({ isOpen, onClose, loanData, title }) => {
  if (!isOpen) return null;

  console.log(loanData?.prin_os, "loanData");

  return (
    <div className="side-modal-overlay">
      <div className="side-modal">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h3>{title}</h3>
        <div className="loan-details-sidePop">
          {title === "Loan Details" && (
            <>
              <div className="loan-details-field">
                <p>
                  <strong className="loan-details-field-title">Loan ID:</strong>
                </p>
                <p className="loan-details-field-value">{loanData?.bic?.loan_id}</p>
              </div>

              <div className="loan-details-field">
                <p>
                  <strong className="loan-details-field-title">Balance Tenure:</strong>
                </p>
                <p className="loan-details-field-value">
                  {loanData?.bic?.tenure + " " + loanData?.bic?.tenure_type}
                </p>
              </div>

              <div className="loan-details-field">
                <p>
                  <strong className="loan-details-field-title">Loan Amount:</strong>
                </p>
                <p className="loan-details-field-value">₹{loanData?.bic?.sanction_amount}</p>
              </div>

              <div className="loan-details-field">
                <p>
                  <strong className="loan-details-field-title">Disbursement Amount:</strong>
                </p>
                <p className="loan-details-field-value"> ₹{loanData?.bic?.net_disbur_amt}</p>
              </div>

              <div className="loan-details-field">
                <p>
                  <strong className="loan-details-field-title">EMI Amount:</strong>
                </p>
                <p className="loan-details-field-value"> ₹{loanData.repaymentDetails.amount}</p>
              </div>
              <hr />

              <div className="loan-details-field">
                <div style={{paddingTop : "20px"}}>
                <p style={{marginBottom : "4px"}}>
                  <strong className="loan-details-field-title">Principal Outstanding Amount:</strong>
                </p>
                
                <p className="loan-details-field-value">
                  ₹{parseFloat(loanData?.prin_os?.$numberDecimal).toFixed(2)}
                </p>
                </div>
              </div>
            </>
          )}

          {title === "Breakup" && (
            <>
              {loanData.repaymentDetails?.charges?.interestDue > 0 && (
                <div className="brakeup-field">
                  <p>
                    <strong className="breakup-field-title">Interest Due:</strong>
                  </p>
                  <p className="breakup-field-value">{loanData.repaymentDetails.charges?.interestDue}</p>
                </div>
              )}

              {loanData.repaymentDetails?.charges?.principalDue > 0 && (
                <div className="brakeup-field">
                  <p>
                    <strong className="breakup-field-title">Due Principle:</strong>
                  </p>
                  <p className="breakup-field-value">{loanData.repaymentDetails?.charges?.principalDue}</p>
                </div>
              )}

              {loanData.repaymentDetails?.charges?.lpiDue > 0 && (
                <div className="brakeup-field">
                  <p>
                    <strong className="breakup-field-title">Late Payment Interest:</strong>
                  </p>
                  <p className="breakup-field-value">{loanData.repaymentDetails?.charges.lpiDue}</p>
                </div>
              )}

              {loanData.repaymentDetails?.charges?.installmentAmount > 0 && (
                <div className="brakeup-field">
                  <p>
                    <strong className="breakup-field-title">Installment Amount:</strong>
                  </p>
                  <p className="breakup-field-value">{loanData.repaymentDetails?.charges.installmentAmount}</p>
                </div>
              )}

              {loanData.repaymentDetails?.charges?.chargeAmount > 0 && (
                <div className="brakeup-field">
                  <p>
                    <strong className="breakup-field-title">Charges:</strong>
                  </p>
                  <p className="breakup-field-value">{loanData.repaymentDetails?.charges?.chargeAmount}</p>
                </div>
              )}

              <hr style={{ marginTop: "20px", borderColor: "#f3eeee" }} />

              {loanData.repaymentDetails?.charges?.excessPayment > 0 && (
                <div className="brakeup-field">
                  <p>
                    <strong className="breakup-field-title">Surplus Amount:</strong>
                  </p>
                  <p className="breakup-field-value">-{loanData.repaymentDetails?.charges?.excessPayment}</p>
                </div>
              )}
            </>
          )}
        </div>
          <button className="cancel-button" onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Sidepopup;

