import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa'; // Import arrow icon
import "./Header.css";
import logo from "../../resources/images/Group10.svg";
import userimg from "../../resources/images/Dropdown.png";

/**
 * @author Rajesh
 * @description Header contains company logo
 * @returns Header component
 */
const Header = ({
    loginUser,
    fullName,
    setModel1,
    setCategoryScreen,
    setModel2,
    setLoginUser
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleLogout = () => {
        setModel1(false);
        setCategoryScreen(false);
        setModel2(false);
        setLoginUser(false);
    };

    return (
        <div className='header-section'>
            <div>
                <div className='header'>
                    <img className='header-image' src={logo} alt="logo" />
                </div>
                {loginUser && fullName && (
                    <div className='user'>
                        <div
                            className='user-icon'
                            onClick={() => setDropdownOpen(!dropdownOpen)} 
                        >
                            <img src={userimg} alt="user-icon" className="user-icon-img" />
                            <span>{fullName}</span>
                            <FaChevronDown className='dropdown-arrow' />
                        </div>
                        {dropdownOpen && (
                            <div className='dropdown-menu'>
                                <button className='logout-button' onClick={handleLogout}>Logout</button>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className='ruler'></div>
        </div>
    );
};

export default Header;
