// src/components/PaymentSuccess.js
import React from "react";
import "./PaymentStyles.css"; // Add this file for styling if necessary

const PaymentSuccess = ({ onBack }) => {
  return (
    <div className="result-container">
      <div className="result-icon success">
        <i className="checkmark">✓</i>
      </div>
      <h1>Payment Successful</h1>
      <p>Your payment has been processed successfully.</p>
      <button className="action-button" onClick={onBack}>
        Go Back
      </button>
    </div>
  );
};

export default PaymentSuccess;
