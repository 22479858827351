import { useState } from "react";
import "./Category.css";
import axios from "axios";
import { GrDocumentPdf } from "react-icons/gr";
import paymentImage from "../../resources/images/payment.png";
import soaImage from "../../resources/images/soa.png";

import BorrowerDetails from "../../Screens/BorrowerDetails/BorrowerDetails";

/**
 * @author Rajesh
 * @description Alert component alert the message with type as failure or success
 * @param {Object} Object {type, message, handleClose}
 * @returns Alert component
 */
const Category = ({
    setModel1,
    loanId,
    setSoaStatus,
    setSoaRequestId,
    setSoaRemark,
    setSoaFileUrl,
    setSoaRequestedDate,
    setOpen,
    setAlertType,
    setAlertMessage,
    fullName

}) => {
  const [showBorrowerDetails, setShowBorrowerDetails] = useState(false);

  const showDownloadScreen = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/soa-request/${loanId}`
      );
      if (response?.data?.status === "success") {
        setSoaRequestId(
          response?.data?.data?.data?.previousGenerated?.request_id
        );
        setSoaStatus(response?.data?.data?.reqSoaData?.status);
        setSoaRequestedDate(response?.data?.data?.reqSoaData?.requested_date);
        if (response?.data?.data?.reqSoaData?.remarks) {
          setSoaRemark(response?.data?.data?.reqSoaData?.remarks);
        } else if (response?.data?.data?.reqSoaData?.file_url) {
          setSoaFileUrl(response?.data?.data?.reqSoaData?.file_url);
        }
        setModel1(true);
      }
      if (response?.data?.status === "error") {
        setAlertType("failure");
        setAlertMessage("something went wrong");
        setOpen(true);
      }
    } catch (error) {
      setAlertType("failure");
      setAlertMessage(error.message);
      setOpen(true);

    }
  };

  const handleRepaymentClick = () => {
    // Set state to show BorrowerDetails
    setShowBorrowerDetails(true);
  };

  return (
    <>
      {showBorrowerDetails ? (
        <BorrowerDetails fullName = {fullName} setShowBorrowerDetails = {setShowBorrowerDetails} loanId={loanId} />
      ) : (
        <div className="background-content-category">
          <h1 className="category-title">Select Category</h1>
          <div className="all-products">


            <div className="product-item" onClick={showDownloadScreen}>
              <div className="product-icon">
                <img src={soaImage} alt="soa-image" />
              </div>
              <p className="product-title">SOA</p>
            </div>

            <div  className="product-item" onClick={handleRepaymentClick}>
              <div className="product-icon">
                <img src={paymentImage} alt="payment-image" />
              </div>
              <p className="product-title">Repayment</p>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default Category;
