import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import axios from "axios";
import PaymentSuccess from "../paymentModal/PaymentSuccess";
import PaymentError from "../paymentModal/PaymentError";
import "./QRCodeGenerator.css";

const QRCodeGenerator = ({
  loanId,
  isOpen,
  onClose,
  loanData,
  setDataSuccess,
  fullName
}) => {
  const [loading, setLoading] = useState(false);
  const [upiLink, setUpiLink] = useState(""); // Store generated UPI link
  const [viewState, setViewState] = useState("QR"); // States: 'QR', 'Success', 'Error'
  const amountToShow = loanData?.repaymentDetails?.amount || "0";

  // Generate UPI link
  useEffect(() => {
    const generateUpiLink = (loanId, fullName, amountToShow) => {
      const referenceId = loanId ? loanId.slice(-12) : "defaultRefID";
      return `upi://pay?pa=mam.123456@icici&pn=${encodeURIComponent(
        fullName || "Customer Name"
      )}&tr=${referenceId}&am=${amountToShow || "0"}&cu=INR&mc=6012`;
    };


    const link = generateUpiLink(
      loanData?.loan_id,
      fullName,
      amountToShow
    );

    setUpiLink(link);
  }, [loanData]);



  const repaymentDetails = async () => {
    try {
      setLoading(true); // Start loading state
      // API call to fetch transaction history
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/repayment-details/${loanId}/${amountToShow}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      // Check if the response is successful
      if (response?.status === 200 && response?.data?.status === "success") {
        setViewState("Success"); // Switch to Success View
        setDataSuccess(true)
      } else {
        // If the response status is not success, show error
        throw new Error("API did not return success status.");
      }
    } catch (error) {
      // If there was an error with the API request or response
      setViewState("Error"); // Switch to Error View
      setDataSuccess(false)

    } finally {
      setLoading(false); // End loading state
    }
  };
  
  useEffect(() => {
    const callAPI = async () => {
      await repaymentDetails();
    };
  
    if (isOpen) {
      // Show QR code for 5 minutes before calling the repaymentDetails API
      const qrCodeTimer = 
              callAPI();

  
      // Cleanup timers on component unmount or when isOpen becomes false
      return () => {
        clearTimeout(qrCodeTimer);
      };
    }
  }, [isOpen]); // Watch for viewState to trigger modal display
  
  


  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      {viewState === "QR" && (
        <div className="modal-content">
          <h2>Scan QR Code</h2>
          <QRCode value={upiLink} />
          <div className="payable-amount">
            <p>Payable Amount</p>
            <h3>₹{loanData?.repaymentDetails?.amount || "0"}</h3>
          </div>
          <p> Please wait while we complete the transaction. This may take up to 5 minutes. Do not refresh the page.</p>
          {loading && <p>fetching the response.....</p> }
        </div>
      )}

      {viewState === "Success" && (
        <PaymentSuccess
          onBack={() => {
            setViewState("QR"); // Reset to QR Code View
            onClose(); // Close Parent Modal
          }}
        />
      )}

      {viewState === "Error" && (
        <PaymentError
          onBack={() => {
            setViewState("QR"); // Reset to QR Code View
            onClose(); // Close Parent Modal
          }}
        />
      )}
    </div>
  );
};

export default QRCodeGenerator;
